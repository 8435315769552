import CardModuleMulti from "../components/CardModuleMulti";
import { useState, useEffect, useRef } from "react";
import api from "../api.js";
import MapModule from "../components/MapModule";
import Button from "@mui/material/Button";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import Typography from "@mui/material/Typography";
import Buttons from "../components/Buttons";
import CustomizedDialogs from "../components/DownloadAlert";
import CustomizedDialogsInfo from "../components/InfoAlert";
import CustomizedDialogsFilter from "../components/FilterAlert";

const API_URL = process.env.REACT_APP_API;

export default function Explore() {
  const [tripId, setTripId] = useState(null);
  const [photoId, setPhotoId] = useState(0);
  const [displayOption, setDisplayOption] = useState("gallery");
  const [openGpxDialog, setOpenGpxDialog] = useState(false);
  const [openInfoDialog, setOpenInfoDialog] = useState(false);
  const [openFilterDialog, setOpenFilterDialog] = useState(false);
  const [imgLoaded, setImgLoaded] = useState(false);
  const [currentTrip, setCurrentTrip] = useState(null);
  const [mapId, setMapId] = useState(100);
  const [filterOption, setFilterOption] = useState("All");
  const [trips, setTrips] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  // const [page, setPage] = useState(1);

  // Lets change setPage to useRef
  const refPage = useRef(1);

  const ref = useRef(null);

  const [scrollTop, setScrollTop] = useState(0);
  const refBlockScroll = useRef(false); //Prevents unwanted repetitions of fetch requests

  const initPolygon = {
    polyline: [
      {
        lat: 45.931355925276875,
        lng: 10.892878202721477,
      },
    ],
  };

  useEffect(() => {
    readCards();
    // const polygon = initPolygon;
  }, []);

  useEffect(() => {
    if (tripId === null) return;
    setPhotoId(0);
    setCurrentTrip(trips.find((trip) => trip.id === tripId));
  }, [tripId]);

  useEffect(() => {
    if (tripId === null) return;
    refPage.current = 1; //added
    setTrips([]); //added
    readCards();
    setPhotoId(0);
    setMapId(100);
    setCurrentTrip(trips.find((trip) => trip.id === tripId));
    setDisplayOption("gallery");
  }, [filterOption]);

  const readCards = async () => {
    setIsLoading(true);
    console.log(isLoading);
    setError(null);

    const filtrationString =
      filterOption === "All" ? "" : "&filters[trackLink][$eq]=" + filterOption;
    const string =
      "/api/cards?populate=*&sort=tripDate" +
      filtrationString +
      `&pagination[page]=${refPage.current}&pagination[pageSize]=6`;
    console.log("filtrationString", string, isLoading);

    const res = await api
      .get(string)
      .then((res) => {
        const data = res.data.data;
        setTrips((prevItems) => [...prevItems, ...data]);
        refPage.current += 1; //changed from useState to useRef
        setTripId(data[0].id);
        setIsLoading(false);
      })
      .catch((error) => {
        setError(error);
      })
      .finally((refBlockScroll.current = false));
  };

  const handleScroll = (event) => {
    setScrollTop(event.currentTarget.scrollTop);

    if (
      scrollTop + ref.current.offsetHeight < 0.75 * ref.current.scrollHeight ||
      isLoading ||
      refBlockScroll.current
    ) {
      return;
    }
    {
      setIsLoading(true);
      refBlockScroll.current = true;
      readCards();
    }
  };

  if (!currentTrip) return "loading...";

  const polygon =
    mapId === 100
      ? initPolygon
      : trips.filter((card) => card.id === mapId)[0].attributes.polygon;

  // console.log(polygon);
  // console.log(trips);
  // console.log(mapId);

  // console.log(trips[0].attributes.gpxtrack.data[0].attributes.url);

  const gpxFileUrl =
    API_URL +
    // "http://localhost:1337" +
    currentTrip.attributes.gpxtrack.data[0].attributes.url;
  const { tripInfo, cardTitle, difficulty, distance, heightGain, startPoint } =
    currentTrip.attributes;
  const tripGallery = currentTrip.attributes.gallery.data;
  // console.log(tripGallery[0].attributes.name);

  tripGallery.sort((a, b) => {
    const nameA = a.attributes.name;
    const nameB = b.attributes.name;
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }

    // names must be equal
    return 0;
  });

  return (
    <div className="responsiveOuterBox">
      <div
        className="responsiveCardBox"
        id="box"
        ref={ref}
        onScroll={handleScroll}
      >
        {trips.map((item) => {
          const attributes = item.attributes;

          // const ImageUrl = "http://localhost:1337" + attributes.cardCover.data.attributes.formats.large.url

          return (
            <div key={item.id}>
              <CardModuleMulti
                cardTitle={attributes.cardTitle}
                tripDate={attributes.tripDate}
                cardCover={attributes.cardCover}
                difficulty={attributes.difficulty}
                distance={attributes.distance}
                heightGain={attributes.heightGain}
                trackLink={attributes.trackLink}
                imageUrl={
                  API_URL +
                  // "http://localhost:1337" +
                  attributes.cardCover.data.attributes.formats.medium.url
                }
                setCardId={setTripId}
                cardId={item.id}
                setPhotoId={setPhotoId}
                setDisplayOption={setDisplayOption}
                displayOption={displayOption}
                setMapId={setMapId}
                tripInfo={attributes.tripInfo}
                startPoint={attributes.startPoint}
              />
            </div>
          );
        })}
      </div>

      <div
        className="responsiveSideBox"
        style={{
          display: "flex",
          // flexwrap: "wrap",
          flexDirection: "column",
          // backgroundColor: "#1976d2",
          width: "80%",
          justifyContent: "space-around",
          overflow: "hidden",
          marginRight: "30px",
          marginLeft: "30px",
          flexGrow: "1",
        }}
      >
        <div
          style={{
            minHeight: "40px",
          }}
        >
          <Typography variant="h5" color="white" sx={{ mt: 1 }}>
            {cardTitle}
          </Typography>
        </div>

        <div
          className="mapBox"
          style={{
            display: displayOption === "map" ? "flex" : "none",
            flexGrow: "1",
            backgroundColor: "red",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <MapModule
            cardId={tripId}
            polygon={polygon}
            cardItemsList={trips}
            mapId={mapId}
          />
        </div>

        <div
          style={{
            display: displayOption === "gallery" ? "flex" : "none",
            flexDirection: "column",
            flexGrow: "1",
          }}
        >
          <div
            className="newGalleryContainer"
            style={{
              backgroundImage:
                'url("' +
                API_URL +
                // "http://localhost:1337" +
                tripGallery[photoId].attributes.formats.xlarge.url +
                '")',
              flexGrow: "1",
            }}
          ></div>

          {imgLoaded && photoId < tripGallery.length - 1 && (
            <img
              className="preload-img"
              src={
                API_URL +
                // "http://localhost:1337" +
                tripGallery[photoId + 1].attributes.formats.xlarge.url
              }
              alt="Italian Trulli"
            ></img>
          )}
          <div
            className="arrowsBox"
            style={{
              display: "flex",
              flexwrap: "wrap",
              flexDirection: "row",
              justifyContent: "space-around",
              marginTop: "2px",
            }}
          >
            <Button
              size="large"
              variant="outlined"
              startIcon={<ArrowBackIosIcon />}
              sx={{ color: "white" }}
              onClick={() => {
                if (photoId > 0) {
                  setPhotoId(photoId - 1);
                  console.log(photoId);
                  setImgLoaded(false);
                }
              }}
              disabled={photoId < 1}
            >
              BACK
            </Button>

            <Button
              size="large"
              variant="outlined"
              // endIcon={<ArrowForwardIosIcon />}
              sx={{
                color: "gray",
                // borderColor: "white",
              }}
              onClick={() => {
                if (photoId < tripGallery.length - 1) {
                  setPhotoId(photoId + 1);
                  console.log(photoId);
                }
              }}
            >
              {photoId + 1} / {tripGallery.length}
            </Button>

            <Button
              size="large"
              variant="outlined"
              endIcon={<ArrowForwardIosIcon />}
              sx={{
                color: "white",
                // borderColor: "white",
              }}
              onClick={() => {
                if (photoId < tripGallery.length - 1) {
                  setPhotoId(photoId + 1);
                  console.log(photoId);
                  setImgLoaded(true);
                }
              }}
              disabled={photoId >= tripGallery.length - 1}
            >
              NEXT
            </Button>
          </div>
        </div>

        <Buttons
          setDisplayOption={setDisplayOption}
          setOpenGpxDialog={setOpenGpxDialog}
          setOpenInfoDialog={setOpenInfoDialog}
          setOpenFilterDialog={setOpenFilterDialog}
          setFilterOption={setFilterOption}
          filterOption={filterOption}
          cardId={tripId}
          setMapId={setMapId}
        />

        {openGpxDialog && (
          <CustomizedDialogs
            openGpxDialog={openGpxDialog}
            setOpenGpxDialog={setOpenGpxDialog}
            gpxFileUrl={gpxFileUrl}
            cardTitle={cardTitle}
          />
        )}

        {openInfoDialog && (
          <CustomizedDialogsInfo
            openInfoDialog={openInfoDialog}
            setOpenInfoDialog={setOpenInfoDialog}
            tripInfo={tripInfo}
            cardTitle={cardTitle}
            difficulty={difficulty}
            distance={distance}
            heightGain={heightGain}
            startPoint={startPoint}
          />
        )}

        {openFilterDialog && (
          <CustomizedDialogsFilter
            openFilterDialog={openFilterDialog}
            setOpenFilterDialog={setOpenFilterDialog}
            setFilterOption={setFilterOption}
            filterOption={filterOption}
          />
        )}
      </div>
    </div>
  );
}
